define('modules/lastcard/socialside/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.socialfeed"], function(module,config,$,util,templates) {
    return new module({
        name: 'lastcard',
        selectors: '.lastCard',
        remoteData: [config.urls.news],

        config: {
            feedItems: [],
            socialAccounts: {
                twitter: {
                    consumer_key: '1B4sLBw8yLl4gWRavnPLQc9IK',
                    consumer_secret: 'zYUDMZKprkOb0sO6XwgRTOvjK3s1apLdxxJaFerHXNzdi6oDcE',
                    template: '<div class="social-feed-element {{? !it.moderation_passed}}hidden{{?}}" dt-create="{{=it.dt_create}}" social-feed-id = "{{=it.id}}">' +
                    '<div class="content">' +
                    '<div class="media-body">' +
                    '<div class="text-wrapper">' +
                    '<i class="icon-icon-twitter "></i>' +
                    '<p class="social-feed-text">{{=it.text}}</p>' +
                    '</div>' +
                    '<a class="pull-left twitter-user-icon" href="{{=it.author_link}}" target="_blank">' +
                    '<img class="media-object" src="{{=it.author_picture}}">' +
                    '</a>' +
                    '<div class="author-bottom"><a href="{{=it.author_link}}" target="_blank">{{=it.author_name}}</a></div>' +
                    '</div>' +
                    '</div>' +
                    '{{=it.attachment}}' +
                    '</div>'
                }
            },
            profanityFilter: false
        },

        initialize: function (newsData) {
            $(this.getSelectors()).append(templates.lastcard());
            $.each(this.getConfig().feedItems,$.proxy(function(index,item){
                if(item.type == 'twitter') {
                    var socialFeedEl = $('<div class="socialFeedEl layout-vertical-center"></div>');
                    var feedOptions = {
                        length: 200,
                        template_html: this.getConfig().socialAccounts[item.type].template,
                        moderation: (this.getConfig().profanityFilter ? function(content) {
                            return (content.text ? !profanityFilter.check(content.text) : false);
                        } : function(){return true;}),
                        callback: $.proxy(function() {
                            var clonedEl = socialFeedEl.clone();
                            $(clonedEl).find('.author-bottom > a').text(item.account);
                            $(this.getSelectors()).find('.twitter-feed-single').append(clonedEl);
                            socialFeedEl.remove();
                        },this)
                    };
                    feedOptions[item.type] = $.extend({
                        accounts: [item.account],
                        limit: 1
                    },this.getConfig().socialAccounts[item.type]);
                    socialFeedEl.socialfeed(feedOptions);
                } else if(item.type == 'news') {
                    $(this.getSelectors()).find('.social-side-inner-container').append(templates.lastcardnewsbox(newsData[0]));
                }
            },this));
		}

    });
});
