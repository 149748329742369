define('modules/results/participants/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates"], function (module, config, $, util, templates) {
    return new module({
        name: 'participants',
        selectors: '.resultsParticipantsWrap',
        remoteData: [config.urls.resultsparticipants],
        routes: [],
        config: {

        },

        initialize: function (data) {

            this.data = (data || this.data);
            this.renderTemplates(this.data);
        },
        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length;
                });

                $(this.getSelectors()).append(templates.participantspage({
                    stat: data
                }));


            }
        }
    });
});

