define('modules/finalists/basic/js/base.js',['app/config', 'jquery', 'templates', 'skrollr', 'waypoints'], function (config, $, templates, skrollr) {
    return {
        name: 'finalists',
        selectors: '.finalistContainer',
        remoteData: [config.urls.finalists],
        //remoteData: [],
        routes: [
            {
                route: 'finalists/(.*)',
                fn: function (hash) {
                    this.goToHash(hash);
                },
                extra: true,
                delay: 2000
            }],


        initialize: function (data) {
            var initHtml;
            this.data = (data || this.data);
            this.renderTemplates(this.data);
        },


        goToHash: function (hash) {
            var scrollToTeam = hash.split('/')[1];
            console.log(scrollToTeam);

        },


        renderTemplates: function (data) {
            $.each(data, function (i, r) {
                data[i].total = data.length;
            });

            $(this.selectors).append(templates.finalists({
                steps: data
            }));
        },

    }
});
