define('modules/social/js/base.js',["app/config","jquery","app/util","app/module"], function(config,$,util,module) {
    return new module({
        name: 'social',
        selectors: false,
        remoteData: [],

        config: {
            twitterTpl: '<iframe src="https://platform.twitter.com/widgets/tweet_button.html#url=http%3A%2F%2Fwww.genhchallenge.com&amp;counturl=www.genhchallenge.com&amp;text=%23GenH%20Challenge%3A%20a%20global%20social%20venture%20competition%20supporting%20people%20on%20the%20front%20lines%20of%20care!%20%40JNJGlobalHealth&amp;count=none&amp;size=m&amp;dnt=true" scrolling="no" frameborder="0" allowtransparency="true" style="border: none; overflow: hidden; width: 62px; height: 21px;"></iframe>',
            googleTpl: '<div class="g-plusone" data-href="http://www.genhchallenge.com" data-size="standard" data-annotation="bubble" data-recommendations="false" id="___plusone_0"></div>',
            facebookTpl: '<div class="fb-share-button" data-href="http://www.genhchallenge.com" data-layout="button_count" data-size="small" data-mobile-iframe="true"><a class="fb-xfbml-parse-ignore" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.genhchallenge.com%2F&amp;src=sdkpreparse">Share</a>'
        },

        initialize: function () {
            var clickFn = this.onSocialClick;
            $('.shareSocial').livequery(function(){
                $(this).on('click',clickFn);
            },function(){
                $(this).off('click',clickFn);
            });
            $('.socialShareCounter').livequery($.proxy(function(index,element){
                this.addShareCounter(element);
            },this));
        },

        methods: {

            // This method is scoped to the element itself, not the module class
            onSocialClick: function (e) {
                e.preventDefault();
                e.stopPropagation();

                var loc = location.href,
                    action = $(this).attr('data-action');

                if (action == 'twitter') {
                    var title = $(this).attr('data-title') || document.title;
                    window.open('//twitter.com/intent/tweet?url=' + encodeURIComponent(loc) + '&text=' + encodeURIComponent(title), 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'facebook') {
                    var t = $(this).attr('data-title') || document.title;
                    window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'youtube') {
                    var title = $(this).attr('data-title') || document.title;
                    window.location.assign("https://www.youtube.com/watch?v=" + title);
                } else if (action == 'google') {
                    window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                }
            },

            addShareCounter: function (element) {
                var action = $(element).attr('data-action');
                if (action == 'twitter') {
                    $(element).html(this.getConfig().twitterTpl);
                } else if(action == 'facebook') {
                    $(element).html(this.getConfig().facebookTpl);
                    (window.FB && FB.XFBML.parse($(element)[0]));
                } else if(action == 'google') {
                    $(element).html(this.getConfig().googleTpl);
                }
            }
        }
    });
});
