define('modules/animations/scroll/js/base.js',["app/config","jquery","app/util","jquery.livequery"], function(config,$,util) {
    return {
        name: 'scroll',
        selectors: false,
        remoteData: [],

        initialize: function() {

            // Add scroll link handlers
            $('.downArrow').click(function(){
                $('html, body').animate({
                    scrollTop: $(window).scrollTop()+($(window).height()*0.9)
                }, 500);
                return false;
            });

            // TODO create config and possibly own method for the scroll to top handling
            $('.js-scrollTop').click(function(){
                $(this).fadeOut();
                $('html, body').animate({
                    scrollTop:0
                }, 1000);
                $('.dashboard-menu-container').removeClass('stickySideNavBar');
                $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                $('.results-page .navbarWrap').addClass('results-nav');
            });

            // TODO this included
            $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', util.minScrollSpeed($.proxy(function (event) {

                var scrollTop = $(window).scrollTop();
                var distanceFromBottom = Math.floor($(document).height() - $(document).scrollTop() - $(window).height());

                //scroll down
                if (scrollTop > this.lastScrollTop && scrollTop > 0) {

                    if (scrollTop > 800) {
                       $('.js-scrollTop').fadeIn();
                    }
                    if (distanceFromBottom < 500) {
                        $('.js-scrollTop').addClass('push-up');
                    }
                }

                //scroll up
                else {
                    if (scrollTop < 800) {
                        $('.js-scrollTop').fadeOut();
                    }
                    if (distanceFromBottom > 200) {
                        $('.js-scrollTop').removeClass('push-up');
                    }

                }

                //at top
                if (scrollTop == 0) {
                    $('.js-scrollTop').fadeOut();
                }

                this.lastScrollTop = scrollTop;
            }, this)));


            // Any elements with "data-scrollto" attribute with a valid selector, when clicked will scroll to that element
            $('*[data-scrollto]').livequery(function() {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($('body').hasClass('spappcard')) {
                        $('body').trigger('snaptocard', $($(this).attr('data-scrollto')));
                    }
                    else {
                        if ($(this).attr('data-scrollto').length > 0) {
                            util.scrollElToTop($($(this).attr('data-scrollto')), ($(this).attr('data-scrolloffset') || 0));
                        }
                    }
                });
            });

            // Section smoothed linking
            $('.smoothBtn').click(function(e){
                e.preventDefault();
                var linkTop = $(this).attr('href');
                if (linkTop) {
                    $('body').animate({scrollTop: $(linkTop).offset().top});
                }
            });

            // Stop overscroll on all classed elements
            $('.stopOver').livequery(function(){
                util.preventOverscroll($(this));
            },function(){
                util.preventOverscroll($(this),false);
            });
        }
    }
});
