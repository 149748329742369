define('modules/results/quotes/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates"], function (module, config, $, util, templates) {
    return new module({
        name: 'quotes',
        selectors: '.resultsJudgeQuotes',
        remoteData: [config.urls.resultsquotes],
        routes: [],
        config: {

        },

        initialize: function (data) {

            this.data = (data || this.data);
            this.renderTemplates(this.data);
        },
        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length;
                });

                $(this.getSelectors()).append(templates.quotespage({
                    quote: data
                }));

                $(this.getSelectors()).find('.carousel-inner .item:first').addClass('active');
                $(this.getSelectors()).find('.carousel-indicators li:first').addClass('active');
                $(this.getSelectors()).find('#resultsQuoteCaro').carousel({
					pause: "false"
				});

            }
        }
    });
});

