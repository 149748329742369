define('modules/leadership/js/base.js',["app/config","jquery","app/module","app/util","templates"], function(config,$,module,util,templates) {
    return new module({
        name: 'leadership',
        selectors: false,
        remoteData: [config.urls.leadership],

        routes: [{
            route: 'designing-the-genh-challenge',
            fn: 'showLeadershipPage'
        }],

        config: {
            sortByLastName: false
        },

        initialize: function (data) {
            this.data = data;
            if(this.queuePage) {
                this.showLeadershipPage();
                this.queuePage = false;
            }
        },

        methods: {
            showLeadershipPage: function() {
                if(!this.data) {
                    this.queuePage = true;
                    return;
                }
                var tplData = (this.getConfig().sortByLastName ? this.data.sort(function(a,b){
                    return a['name'].split(' ').slice(-1).pop() > b['name'].split(' ').slice(-1).pop() ? 1 : a['name'].split(' ').slice(-1).pop() < b['name'].split(' ').slice(-1).pop() ? -1 : 0;
                }) : this.data);
                var pageEl = $(templates.leadershippage(tplData));
                $('#centerMenuContent').addClass('sidebarPage');
                $('body').trigger('updatedashboard',[true,pageEl.clone(true,true).removeClass('hidden')]);
            }
        }

    });
});
