// Google Maps helper
// TODO - Add global check for gmaps dependency, add loader method/callback
define('app/features/map',["app/config","jquery","app/router"], function(config,$,router) {
    return {

        restrictMapPanning: function(map,styles) {

            new google.maps.Rectangle({
                strokeColor: '#20448e',
                strokeOpacity: 1,
                strokeWeight: 0,
                fillColor: '#20448e',
                fillOpacity: 1,
                map: map,
                bounds: {
                    north: -60,
                    south: -90,
                    east: 180,
                    west: -180
                }
            });

            return;

            var lastValidCenter = map.getCenter();
            google.maps.event.addListener(map, 'center_changed', function () {
                if(map.getZoom() < 3) {
                    var ne = map.getBounds().getNorthEast(),
                        sw = map.getBounds().getSouthWest(),
                        center = map.getCenter();
                    if (ne.lng() >= 180 || sw.lng() <= -180 || center.lng() >= 180 || center.lng() <= -180) {
                        map.panTo(lastValidCenter);
                    } else if (ne.lat() > 85 || sw.lat() < -60) { // Restrict of arctic circle @ 66.5N
                        map.panTo(lastValidCenter);
                    } else {
                        lastValidCenter = center;
                    }
                }
            });
            /*google.maps.event.addListener(map, 'zoom_changed', function () {
                setTimeout(function(){
                    if(map.getBounds().getNorthEast().lng() >= 180 || map.getBounds().getSouthWest().lng() <= -180) {
                       lastValidCenter = new google.maps.LatLng(0,0); // TODO - Improve this fallback handling
                       map.panTo(lastValidCenter);
                    }
                },100);
            });*/

            google.maps.event.addListener(map, 'zoom_changed', function () {
                setTimeout(function(){
                    if(map.getZoom() < 3) {

                    }
                },10);
            });


            var getNormalizedCoord = function(coord, zoom) {
                var y = coord.y;
                var x = coord.x;

                // tile range in one direction range is dependent on zoom level
                // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
                var tileRange = 1 << zoom;

                // don't repeat across y-axis (vertically)
                if (y < 0 || y >= tileRange) {
                    return null;
                }

                // repeat across x-axis
                if (x < 0 || x >= tileRange) {
                    return null;
                }

                return {x: x, y: y};
            };

        }

    }
});
