define('app/module',['app/util'],
    function(util){

        var instanceCount = 0;

        var getNewInstanceID = function(){
            return( ++instanceCount );
        };

        // Apply base config
        function Module(config){
            this._instanceID = getNewInstanceID();
            this._config = config.config || {};
            this._initialize = config.initialize;
            this._selectors = config.selectors || false;
            this._routes = config.routes || [];
            this._remoteData = config.remoteData || [];
            this._name = config.name || '';
            for(var key in (config.methods || {})) {
                this[key] = config.methods[key];
            }
            return( this );
        }

        // Statics
        Module.getInstanceCount = function(){
            return( instanceCount );
        };
        Module.getConfig = function() {
            return( config );
        };
        Module.initialize = function() {
            return( initialize );
        };
        Module.getRoutes = function() {
            return( routes );
        };
        Module.getRemoteData = function() {
            return( remoteData );
        };
        Module.getName = function() {
            return( name );
        };
        Module.getSelectors = function() {
            return( selectors );
        };

        // Publics
        Module.prototype = {
            initialize: function(data) {
                this._initialize(data);
            },
            getInstanceID: function(){
                return( this._instanceID );
            },
            getConfig: function(){
                var buildConfig = util.getBuildConfig().config,
                    config = this._config;
                if(buildConfig) {
                    for (var key in buildConfig) {
                        if(this.getName().split('.')[0] == key.split('.')[0]) {
                            config = util.extendObj(buildConfig[key],this._config);
                        }
                    }
                }
                return( config );
            },
            getSelectors: function() {
                return( this._selectors );
            },
            getRoutes: function() {
                return( this._routes );
            },
            getRemoteData: function() {
                return( this._remoteData );
            },
            getName: function() {
                return( this._name );
            },
            setSelectors: function(value) {
                this._selectors = value;
            },
            refreshRouteConfig: function() {
                // TODO - We need to implement this
            }
        };
        return( Module );
    }
);
