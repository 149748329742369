define('modules/results/submissions/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "parallax"], function (module, config, $, util, templates, parallax) {
    return new module({
        name: 'submissions',
        selectors: '.resultsSubmissionInfo',
        remoteData: [config.urls.resultssubmissions],
        routes: [],
        config: {
            pxBg: true
        },

        initialize: function (data) {

            this.data = (data || this.data);
            this.renderTemplates(this.data);
        },
        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length;
                });

                $(this.getSelectors()).append(templates.submissioninfocards({
                    cards: data
                }));

                if (this.getConfig().pxBg) {
                    $('*[data-pxscroll]').livequery($.proxy(function(index,el) {
                        var pxEl = $(this.getSelectors()).find('.mouse-parallax-true .fixedBg');
                        $(pxEl).parallax();
                    },this));
                }
            }
        }
    });
});

