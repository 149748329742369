define('modules/faqs/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({

        name: 'faqs',
        selectors: '.faqs',
        remoteData: [config.urls.faqs],

        config: {
            carouselCounters: true
        },

        initialize: function (data) {

            var totalFaqs = data.length;
            var carouselArrays = [], size = 7;

            while (data.length > 0) {
                carouselArrays.push(data.splice(0, size));
            }

            $(this.getSelectors()).find('.faqWrap').append(templates.faqslider({
                records: carouselArrays,
                numFaqs: totalFaqs
            }));

            $(this.getSelectors()).addClass('faq-panel-closed');

            // Carousel
            $(this.getSelectors()).find('#faq-carousel').carousel({
                interval: 0,
                wrap: true
            }).on('slide.bs.carousel',$.proxy(function(e){
                $(this.getSelectors()).find('.item').removeClass('reverse');
                if(e.direction == 'right') {
                    $(e.relatedTarget).addClass('reverse');
                }
                if($(this.getSelectors()).hasClass('faq-panel-closed')) {
                    $(this.getSelectors()).find('.faq-item-selected').removeClass('faq-item-selected').addClass('faq-item');
                }
            },this));

            // Convert the carousel dots into number counter
            if(this.getConfig().carouselCounters) {
                $(this.getSelectors()).find('#faq-carousel .carousel-indicators > li').css('text-indent', '0px').slice(2).hide();
                $(this.getSelectors()).find('#faq-carousel .carousel-indicators > li').eq(0).text('1');
                $(this.getSelectors()).find('#faq-carousel .carousel-indicators > li').eq(1).text($(this.getSelectors()).find('#faq-carousel .carousel-indicators > li').length).addClass('of-li');
                $(this.getSelectors()).find('#faq-carousel').on('slid.bs.carousel',$.proxy(function(e){
                    $(this.getSelectors()).find('.carousel-indicators > li').eq(0).text($(this.getSelectors()).find('.item.active').index()+1);
                },this));
            }

            // Reset the right alignment when carousel item becomes visible
            $(this.getSelectors()).find('.item.active:visible').livequery($.proxy(function(){
                this.rightAlignQuestions($(this.getSelectors()).hasClass('faq-panel-open'));
            },this));

            $(this.getSelectors()).find('.faq-question').on('click', $.proxy(function(e){
                e.preventDefault();
               var faqNumber = $(e.currentTarget).data('faq-number').split('-');
               faqNumber = parseInt(faqNumber[0]) * size + parseInt(faqNumber[1]) + 1;
               $('#answer-content').html($($($(e.currentTarget).data('target') + " .hack1")).html() + $($($(e.currentTarget).data('target') + " .hack2").html()).text()) // TODO - Refactor this to reference data object, not cloned hidden element
               $('#answer-panel #faq-mobile-nav #current-faq').html(faqNumber);
               if($(this.getSelectors()).hasClass('faq-panel-closed')) {
                   this.openFaqPanel();
               }
           },this));

            $(this.getSelectors()).find('#answer-panel .faq-close-icon').on('click', $.proxy(this.closeFaqPanel,this));

            // Display QA on item click
            $(this.getSelectors()).find('.faq').bind('click', function () {
              var previouslySelectedItem = $('.faq-item-selected');
              var selectedItem = $(this);
              if (!previouslySelectedItem) {
                selectedItem.removeClass('faq-item').addClass('faq-item-selected');
              }
              if (previouslySelectedItem) {
                previouslySelectedItem.removeClass('faq-item-selected').addClass('faq-item');
                selectedItem.removeClass('faq-item').addClass('faq-item-selected');
              }
            });

            this.addClickHandlers(totalFaqs, size);

            $(window).smartresize($.proxy(this.refreshSizing,this));
            
            config.refresh();
            $('body').trigger('pagenavupdate');

        },

        methods: {

            refreshSizing: function () {
                this.rightAlignQuestions($(this.getSelectors()).hasClass('faq-panel-open'));
            },

            addClickHandlers: function (totalFaqs, groupSize) {
                $(this.getSelectors()).find('.previous-faq').on('click', function (e) {
                    e.preventDefault();
                    var currentFaq = $(this).siblings('#current-faq');
                    var prevFaq = parseInt(currentFaq.html()) - 2; // account for index starting at 0
                    if ((prevFaq + 1) > 0) {
                        var groupIndex = Math.floor(prevFaq / groupSize);
                        var index = prevFaq % groupSize;
                        $('#answer-content').html($($('#answer-' + groupIndex + '-' + (index)).html()).text()); // TODO - Refactor this to reference data object, not cloned hidden element
                        currentFaq.html(prevFaq + 1);
                    }

                    // wrap loose text in h4
                    $('#answer-content').each(
                        function () {
                            var kids = this.childNodes;
                            for (var i = 0, len = kids.length; i < len; i++) {
                                if (kids[i].nodeName == '#text') {
                                    $(kids[i]).wrap('<h4/>');
                                }
                            }
                        });
                });
                $(this.getSelectors()).find('.next-faq').on('click', function (e) {
                    e.preventDefault();
                    // TODO flexible scoping
                    //var currentFaq = $(this).siblings('#current-faq');
                    var currentFaq = $(this).siblings().find('#current-faq');
                    var nextFaq = parseInt(currentFaq.html()); // index of next is technically the current FAQ #
                    if ((nextFaq + 1) <= totalFaqs) {
                        var groupIndex = Math.floor(nextFaq / groupSize);
                        var index = nextFaq % groupSize;
                        $('#answer-content').html($($('#answer-' + groupIndex + '-' + (index)).html()).text()); // TODO - Refactor this to reference data object, not cloned hidden element
                        currentFaq.html(nextFaq + 1);
                    }

                    // wrap loose text in h4
                    $('#answer-content').each(
                        function () {
                            var kids = this.childNodes;
                            for (var i = 0, len = kids.length; i < len; i++) {
                                if (kids[i].nodeName == '#text') {
                                    $(kids[i]).wrap('<h4/>');
                                }
                            }
                        });

                });

            },

            rightAlignQuestions: function (state) {
                if (state) {
                    $(this.getSelectors()).find('.faq-item, .faq-item-selected').each(function () {
                        var translateAmount = $(this).width() - $(this).find('.faq-question').outerWidth(true);
                        if ($(window).width() >= 768) {
                            $(this).find('.faq-question').css('transform', 'translateX(' + translateAmount + 'px)')
                        }
                    });
                } else {
                    if ($(window).width() >= 768) {
                        $(this.getSelectors()).find('.faq-item .faq-question').css('transform', 'translateX(0px)');
                    }
                }
            },

            closeFaqPanel: function (e) {
                e.preventDefault();
                $(this.getSelectors()).removeClass('faq-panel-open').addClass('faq-panel-closed');
                $(this.getSelectors()).find('#faq-carousel .faq-item-selected').removeClass('faq-item-selected').addClass('faq-item');
                $(this.getSelectors()).find('.carousel-control-wrapper').addClass('slideOut');
                setTimeout($.proxy(function () {
                    this.rightAlignQuestions(false);
                }, this), 200);
                setTimeout($.proxy(function () {
                    $(this.getSelectors()).find('.carousel-control-wrapper').hide().removeClass('leftAlign').addClass('slideOut');
                },this), 500);
                setTimeout($.proxy(function(){
                    $(this.getSelectors()).find('.carousel-control-wrapper').show();
                    setTimeout($.proxy(function(){
                        $(this.getSelectors()).find('.carousel-control-wrapper').removeClass('slideOut');
                    },this), 200);
                },this), 1500);
            },

            openFaqPanel: function () {
                $(this.getSelectors()).removeClass('faq-panel-closed').addClass('faq-panel-open');
                $(this.getSelectors()).find('.carousel-control-wrapper').addClass('slideOut');
                setTimeout($.proxy(function () {
                    this.rightAlignQuestions(true);
                }, this), 200);
                setTimeout($.proxy(function(){
                    $(this.getSelectors()).find('.carousel-control-wrapper').hide().addClass('leftAlign').removeClass('slideOut slideIn');
                },this), 500);
                setTimeout($.proxy(function(){
                    $(this.getSelectors()).find('.carousel-control-wrapper').show();
                    setTimeout($.proxy(function(){
                        $(this.getSelectors()).find('.carousel-control-wrapper').addClass('slideIn');
                    },this), 200);
                },this), 1500);

                // TODO specific to genH
                $('.dashboard-menu-container').removeClass('slide-from-right');
            }
        }
    });
});

