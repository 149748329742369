define('modules/subnav/js/base.js',["app/module","app/config","jquery","app/util","templates"], function(module,config,$,util,templates) {
    return new module({
        name: 'subnav',
        selectors: false,
        remoteData: [],

        routes: [{
            route: '.*',
            fn: 'showSubnav',
            extra: true,
            delay: 50
        }],

        initialize: $.noop,

        methods: {

            showSubnav: function(hash) {
                this.hideSubnav();
                if($('.pageContainer[data-route="'+hash+'"]').attr('data-subnav')) {
                    $('#mainNavbar').hide();
                    $('.singlePageWrap').prepend(templates.subnavpagebar({
                        pages: this.getPages(hash),
                        hash: hash
                    }));
                    $('.singlePageWrap .subnavWrap .closeSubnav').on('click',$.proxy(function(e){
                        e.preventDefault();
                        this.hideSubnav();
                        window.location.hash = 'home';
                    },this));
                } else {
                    $('.pageContainer[data-subnav]').removeClass('active').hide();
                }
            },

            hideSubnav: function() {
                $('#mainNavbar').show();
                $('.singlePageWrap .subnavWrap').remove();
            },

            getPages: function(hash) {
                var instanceId = $('.pageContainer[data-route="'+hash+'"]').attr('data-subnav-instance');
            
                // TODO add flexibility
                if (instanceId == "2") {
                	$('.subnavWrap').addClass('instance-two');
                }
                
                var pageArray = $('.pageContainer[data-subnav-instance="'+instanceId+'"]').map(function(){
                    var selected = ($(this).attr('data-route') == hash);
                    if($('.pageContainer[data-subnav-group="'+$(this).attr('data-subnav-group')+'"][data-route="'+hash+'"]').length > 0) {
                        selected = true;
                    }
                    return {
                        title: $(this).attr('data-title'),
                        selected: selected,
                        color: $(this).attr('data-subnav-color'),
                        route: $(this).attr('data-route'),
                        group: $(this).attr('data-subnav-group') || $(this).attr('data-title')
                    }
                }).toArray();
                return util.groupRecords(pageArray,'group');
            }

        }

    });
});
