define('modules/finalists/map/js/base.js',["app/module","app/config","jquery","app/util","templates","app/features/map","infobubble","oms","markerclusterer","bootstrap/popover","bootstrap/select"], function(module,config,$,util,templates,mapFeature) {
    return new module({
        name: 'finalistsmap',
        selectors: '.finalistsMap',
        remoteData: [config.urls.judges],

        routes: [{
            route: '(results|home|judges)',
            extra: true,
            delay: 3000,
            fn: function(){
                this.refreshMapBounds();
            }
        }],

        config: {
            mapStyles: [],
            icons: {
                default: 'img/finalists-map-marker-default.png',
                selected: 'img/finalists-map-marker-selected.png',
                semifinalist: 'img/finalists-map-marker-default-semi.png',
                'Evaluation Panel': 'img/map-marker-ep.png',
                'Selection Committee': 'img/map-marker-sc.png',
                'Peer to Peer Review': 'img/map-marker-pr.png'
            },
            downloadLink: true,
            dropdownLabel: 'Filter by Category',
            dropdownStyle: 'sidepanel',
            oms: true,
            clickPopover: false,
            navigateRecords: false,
            disableGroupBios: [],
            clusterList: false
        },

        initialize: function (data) {
			
            var groups = util.unique($.map(data,function(r){return r.type;}));
            this.infoWindows = [];
            this.selectedMarker = null;
            this.markerClusters = [];
            this.initialPosition = true;

            if(window.google && window.google.maps) {
                $(this.getSelectors()).html(templates.finalistsmap({
                    groups: groups,
                    downloadLink: this.getConfig().downloadLink,
                    dropdownLabel: this.getConfig().dropdownLabel,
                    dropdownStyle: this.getConfig().dropdownStyle
                }));
                this.renderMap(data);
                $(this.getSelectors()).find('.categorySort').on('click',$.proxy(this.showCategorySort,this));
                $(this.getSelectors()).find('.mapSidePanel').on('click','.videoThumbnail',$.proxy(this.playVideo,this));
                $(this.getSelectors()).find('.mapSidePanel').on('click','.closeIcon',$.proxy(function(e){
                    e.preventDefault();
                    $(this.getSelectors()).removeClass('open participants');
                    this.closeInfoWindows();
                    this.resetMarkers();
                    this.refreshMapBounds();
                    setTimeout($.proxy(function(){
						$(this.getSelectors()).find('.mapSidePanel').removeClass('participants');
                        $('body').removeClass('locked');
					},this),500);
                },this));
                $(this.getSelectors()).find('.map-video-wrapper').on('click','.video-close',$.proxy(function(e){
                    e.preventDefault();
                    this.stopVideo();
                },this));
                
                this.categories = util.unique($.map(data,function(record){
                    return record.category;
                }));
                this.categories.unshift('All Categories');
                this.data = data;
                $(window).smartresize($.proxy(function(){
                    this.refreshMapBounds();
                },this));
                google.maps.event.addListener(this.map, "click", $.proxy(this.onMapClick,this));

                // judge counts
                this.judgeCounts = [];
                $.each(data,$.proxy(function(index,record){
                	if(this.judgeCounts[record.type] === undefined) {
                    	this.judgeCounts[record.type] = 0;
                	}
                	this.judgeCounts[record.type] += 1;
                },this));

                setTimeout($.proxy(function(){
                    this.setMapCenter();
                },this),1000);

            } else {
                console.debug('Google maps not loaded, could not load any maps.'); // TODO - Add UX error handling
                return;
            }

            this.addFinalistButtonHandlers();
            $(this.getSelectors()).find('.selectpicker').selectpicker();
            $(this.getSelectors()).find('.selectpicker').on('change',$.proxy(function(e){
                var selected = $(e.currentTarget).find("option:selected").val();
                this.setMarkersVisible(function(marker){
                    return (!$(e.currentTarget).selectpicker('val') || $(e.currentTarget).selectpicker('val').indexOf(marker.customData.category) > -1);
                });
            },this));
            $(this.getSelectors()).find('.mapLegend *[data-group]').each($.proxy(function(index,el){
               if(this.getConfig().icons[$(el).attr('data-group')]) {
                   $(el).attr('src',this.getConfig().icons[$(el).attr('data-group')]);
               }
            },this));
            
            $(this.getSelectors()).find('.categorySort .bs-caret').addClass('material-icons');
        },

        methods: {

            createMap: function(element) {
                var map = new google.maps.Map(element,{
                    center: {
                        lat: 0,
                        lng: -60
                    },
                    zoom: 2,
                    maxZoom: 14,
                    minZoom: 2,
                    disableDefaultUI: true,
                    zoomControl: true,
                    scrollwheel: false,
                    backgroundColor: 'none',
                    styles: (this.getConfig().mapStyles || [])
                });
                this.map = map;
                this.markers = [];

                if(this.getConfig().oms) {
                    this.oms = new OverlappingMarkerSpiderfier(this.map, {
                        markersWontMove: true,
                        markersWontHide: true
                    });
                    this.oms.addListener('click',$.proxy(function(marker,e) {
                        this.handleMarkerClick(marker);
                    }, this));
                }

                mapFeature.restrictMapPanning(map);
            },

            renderMap: function(data) {
                this.createMap($(this.getSelectors()).find('.gmapWrap')[0]);
                this.placeMapMarkers(data);
                if(this.getConfig().clusterList) {
                    this.createMarkerClusters();
                }
            },

            createMarkerClusters: function() {
                $.each(this.markerClusters,function(i,cluster){
                    cluster.cluster.removeMarkers(cluster.cluster.getMarkers());
                });
                var clusters = [];
                this.markerClusters = [];
                $.each(this.markers,function(i,v){
                    clusters[v.customData.category] ? clusters[v.customData.category].push(v) : (clusters[v.customData.category] = [v]);
                });
                for(var key in clusters) {
                    var markerCluster = new MarkerClusterer(this.map,clusters[key],{
                        zoomOnClick: false,
                        gridSize: 35,
                        styles: [{
                            textColor: (key == 'Peer to Peer Review' ? '#305296' : 'white'),
                            textSize: 12,
                            url: (this.getConfig().icons[key] ? this.getConfig().icons[key].replace('.png','-blank.png') : this.getConfig().icons.default),
                            width: 28,
                            height: 33,
                            backgroundPosition: '-1px 1px'
                        }]
                    });
                    google.maps.event.addListener(markerCluster, 'clusterclick', $.proxy(this.handleClusterClick,this));
                    this.markerClusters.push({
                        name: key,
                        cluster: markerCluster
                    });
                }
            },

            placeMarker: function(locationData,data) {
                if(typeof locationData.lat != 'number' || typeof locationData.lng != 'number') {
                    console.warn('Invalid location',locationData,data);
                    return;
                }
                var marker = new google.maps.Marker({
                    map: this.map,
                    position: {
                        lat: locationData.lat,
                        lng: locationData.lng
                    },
                    customData: data,
                    icon: (this.getConfig().icons[data.category] || this.getConfig().icons[(data['semifinalist'] ? 'semifinalist' : 'default')])
                });
                marker.setZIndex(google.maps.Marker.MAX_ZINDEX + (data['semifinalist'] ? 1 : 0));
                this.markers.push(marker);
                if(this.getConfig().oms) {
                    this.oms.addMarker(marker);
                } else {
                    marker.addListener('click',$.proxy(function(){
                        this.handleMarkerClick(marker);
                    },this));
                }
            },

            placeMapMarkers: function(data) {
                $.each(data,$.proxy(function(index,record){
                    if(record.locations) {
                        // Finalists
                        $.each(record.locations, $.proxy(function (i, loc) {
                            if (loc) {
                                this.placeMarker(loc, {
                                    id: record.id,
                                    category: record.category,
                                    semifinalist: record.semifinalist
                                });
                            } else {
                                console.debug('Missing location', record)
                            }
                        }, this));
                    } else if(record.location) {
                        // Judges
                        this.placeMarker({
                            lat: record.latitude,
                            lng: record.longitude
                        },{
                            category: record.type,
                            id: record.id
                        });
                    } else {
                        console.debug('Missing location', record)
                    }
                },this));
                //this.refreshMapBounds();
            },

            handleMarkerClick: function(marker,showfull) {
                showfull = (typeof showfull == 'undefined' ? false : showfull);
                if(this.getConfig().clickPopover) {
                    if(this.selectedMarker == marker && this.infoWindows.length > 0) {
                        $(this.getSelectors()).find('.mapInfoWinId-'+marker.customData.id).trigger('click');
                    } else {
                        this.resetMarkers();
                        this.closeInfoWindows();
                        var infowindow = this.createInfoWindow(marker.customData.id,showfull);
                        infowindow.open(this.map,marker);
                        this.setMarkerSelected(marker);
                        if(!showfull) {
                            this.showCategoryInfo(marker);
                        }
                    }
                } else {
                    this.resetMarkers();
                    var markers = $.grep(this.markers,function(m){
                        return m.customData.id == marker.customData.id;
                    });
                    $.each(markers,$.proxy(function(i,m){
                        this.setMarkerSelected(m);
                    },this));
                    this.renderSidePanel(marker.customData.id);
                    $(this.getSelectors()).addClass('open');
                    this.refreshMapBounds(markers);
                }
            },

            handleClusterClick: function(cluster) {
                var records = $.map(cluster.getMarkers(),$.proxy(function(v,i){
                    return this.getRecord(v.customData.id);
                },this));
                $(this.getSelectors()).find('.mapSidePanel').addClass('participants').html(templates.finalistsmapclusterlist(records));
                $(this.getSelectors()).addClass('open participants').get(0).scrollIntoView();
                $('body').addClass('locked');
                $(this.getSelectors()).find('.mapSidePanel .judge-cluster-inner').on('click',$.proxy(function(e){
                    e.preventDefault();
                    $(this.getSelectors()).addClass('open');
                    if(this.getConfig().disableGroupBios.indexOf(this.getRecord($(e.currentTarget).attr('data-id')).type) > -1) {
                        var marker = this.getMarker($(e.currentTarget).attr('data-id'));
                        this.handleMarkerClick(marker,true);
                        this.showCategoryInfo(marker);
                    } else {
                        this.renderSidePanel($(e.currentTarget).attr('data-id'));
                        this.resetMarkers();
                        setTimeout($.proxy(function(){
                            this.setMarkerSelected(this.getMarker($(e.currentTarget).attr('data-id')));
                        },this),200);
                    }
                },this));
            },

            setMarkerSelected: function(marker) {
                marker.setIcon(this.getConfig().icons.selected);
                marker.setZIndex(google.maps.Marker.MAX_ZINDEX+2);
                this.selectedMarker = marker;
                var clusterMarker = null;
                $.each(this.markerClusters,$.proxy(function(i,c){
                    c.cluster.setStyles([{
                        textColor: (c.name == 'Peer to Peer Review' ? '#305296' : 'white'),
                        textSize: 12,
                        width: 28,
                        height: 33,
                        backgroundPosition: '-1px 1px',
                        url: (this.getConfig().icons[c.name] ? this.getConfig().icons[c.name].replace('.png','-blank.png') : this.getConfig().icons.default)
                    }]);
                    c.cluster.repaint();
                    $.each(c.cluster.clusters_,function(ii,cc){
                        if(cc.getMarkers().length > 1 && cc.getMarkers().indexOf(marker) > -1) {
                            clusterMarker = cc;
                        }
                    });
                },this));
                if(clusterMarker) {
                    clusterMarker.clusterIcon_.styles_ = [{
                        textColor: 'white',
                        textSize: 1,
                        width: 37,
                        height: 45,
                        backgroundPosition: '0px 0px',
                        url: this.getConfig().icons['selected']
                    }];
                    clusterMarker.updateIcon();
                }
            },

            createInfoWindow: function(recordId,showfull) {
                var record = this.getRecord(recordId);
                $.extend(record,{
                    disableBio: (this.getConfig().disableGroupBios.indexOf(record.type) > -1)
                });
                var infowindow = new InfoBubble({
                    content: templates.finalistsmapinfowindow(record),
                    maxWidth: 225,
                    minWidth: 225,
                    minHeight:310,
                    padding:0,
                    backgroundColor: 'transparent',
				    //disableAutoPan: true,
				    //hideCloseButton: true,
				    borderRadius: 2,
				    //arrowSize: 5,
				    borderWidth: 0,
				    borderColor: 'transparent',
				    //arrowPosition: 30,
				    //backgroundClassName: '',
				    shadowStyle: 0,
                    closeSrc: util.getEmptyPixelSrc()
                });
                if(!record.disableBio) {
                    google.maps.event.addListener(infowindow, 'domready',$.proxy(function() {
                        $(this.getSelectors()).find('.mapInfoWinId-'+recordId).on('click',$.proxy(function(e){
                            e.preventDefault();
                            this.renderSidePanel(recordId);
                            $(this.getSelectors()).addClass('open').get(0).scrollIntoView();
                            $('body').addClass('locked');
                            this.closeInfoWindows();
                        },this));
                        if(showfull) {
                            $(this.getSelectors()).find('.mapInfoWinId-'+recordId).trigger('click');
                        }
                    },this));
                }
                this.infoWindows.push(infowindow);
                return infowindow;
            },

            closeInfoWindows: function() {
                $.each(this.infoWindows || [],function(i,v){
                    v.close();
                });
                this.infoWindows = [];
            },

            renderSidePanel: function(name) {
                var record = this.getRecord(name);
                if(record) {
                	$(this.getSelectors()).addClass('participants');
                    $(this.getSelectors()).find('.mapSidePanel').addClass('participants').html(templates.finalistsmapsidepanel($.extend(record,{
                        navigateRecords: this.getConfig().navigateRecords
                    })));
                    if(this.getConfig().navigateRecords) {
                        var subIndex = 0;
                        $.each(this.data,function(i,v){
                            if(record.type == v.type) {
                                subIndex++;
                            }
                            if(record.id == v.id) {
                                return false;
                            }
                        });
                        $(this.getSelectors()).find('.judgeMapNav .active').text(subIndex);
                        $(this.getSelectors()).find('.mapSidePanel .judgeMapNav a').on('click',$.proxy(function(e){
                            e.preventDefault();
                            this.navigateRecords($(e.currentTarget).hasClass('nav-next'));
                        },this));
                        $(this.getSelectors()).find('.judgeMapNav .total').text(this.judgeCounts[record.type]);
                    }
                }
            },

            showCategorySort: function(e) {

                var categories = this.categories.sort();

                if(this.getConfig().dropdownStyle == 'sidepanel') {
                    e.preventDefault();
                    categories.splice(0, 0, categories.splice(categories.indexOf('All Categories'), 1)[0]);
                    $(this.getSelectors()).find('.mapSidePanel').html(templates.finalistsmapcategories({
                        categories: categories
                    }));
                    $.each(this.selectedCategories || [], $.proxy(function (index, category) {
                        $(this.getSelectors()).find('.categoryCheckboxListing input[value="' + category + '"]').prop('checked', 'checked');
                        if (category != 'All Categories') {
                            $(this.getSelectors()).find('.categoryCheckboxListing input[value="All Categories"]').prop('checked', '');
                        }
                    }, this));
                    $(this.getSelectors()).addClass('open');
                    google.maps.event.trigger(this.map, "resize");
                    $(this.getSelectors()).find('.categoryCheckboxListing input').on('change', $.proxy(function (e) {
                        var selected = $.map($(this.getSelectors()).find('.categoryCheckboxListing input:checked'), function (v) {
                            return $(v).val();
                        });
                        if (selected.length == 0 || $(e.currentTarget).val() == 'All Categories') {
                            $(this.getSelectors()).find('.categoryCheckboxListing input').prop('checked', '');
                            $(this.getSelectors()).find('.categoryCheckboxListing input[value="All Categories"]').prop('checked', 'checked');
                            this.setMarkersVisible(function(){
                                return true;
                            });
                            selected = [];
                        } else {
                            this.setMarkersVisible(function(marker){
                                return (selected.indexOf(marker.customData.category) != -1);
                            });
                            if (selected.length > 1) {
                                $(this.getSelectors()).find('.categoryCheckboxListing input[value="All Categories"]').prop('checked', '');
                            }
                        }
                        this.selectedCategories = selected;
                        this.refreshMapBounds();
                    }, this));
                }
            },

            showCategoryInfo: function(marker) {
                var record = this.getRecord(marker.customData.id);
                var count = $.grep(this.data,function(v,i){
                    return (v.type == record.type);
                }).length;
                record.count = count;
                $(this.getSelectors()).find('.mapSidePanel').addClass('participants').html(templates.finalistscategoryinfo(record));
                $(this.getSelectors()).addClass('open participants').get(0).scrollIntoView();
                $('body').addClass('locked');
            },

            getRecord: function(id) {
                var found = null;
                $.each(this.data,function(index,record){
                    if(record.id == id) {
                        found = record;
                    }
                });
                return found;
            },

            getMarker: function(id) {
                var found = null;
                $.each(this.markers,function(index,marker){
                    if(marker.customData.id == id) {
                        found = marker;
                    }
                });
                return found;
            },

            setMarkersVisible: function(filterFn) {
                $.each(this.markerClusters, function (index,cluster) {
                    cluster.cluster.clearMarkers();
                });
                $.each(this.markers, $.proxy(function (index, marker) {
                    var state = filterFn(marker);
                    if(state) {
                        this.getClusterByName(marker.customData['category']).addMarker(marker);
                    }
                    marker.setVisible(state);
                },this));
            },

            setMapCenter: function() {
                if(this.map.getZoom() <= 2) {
                    this.map.setCenter(new google.maps.LatLng(35,0));
                }
            },

            refreshMapBounds: function(markers) {
                google.maps.event.trigger(this.map, "resize");
                if(this.initialPosition) {
                    this.map.setCenter(new google.maps.LatLng(35,0));
                    this.initialPosition = false;
                } else {
                    var bounds = new google.maps.LatLngBounds();
                    $.each((markers || this.markers),function(index,marker){
                        if(marker.getVisible()) {
                            bounds.extend(marker.getPosition());
                        }
                    });
                    this.map.fitBounds(bounds);
                    this.setMapCenter();
                }
            },

            resetMarkers: function() {
                $.each(this.markers,$.proxy(function(index,marker){
                    var icon = this.getConfig().icons[marker.customData['category']];
                    if(icon) {
                        marker.setIcon(icon);
                    } else {
                        marker.setIcon(this.getConfig().icons[(marker.customData['semifinalist'] ? 'semifinalist' : 'default')]);
                        marker.setZIndex(google.maps.Marker.MAX_ZINDEX + (marker.customData['semifinalist'] ? 1 : 0));
                    }
                },this));
            },

            getClusterByName: function(name) {
                var foundCluster = null;
                $.each(this.markerClusters,function(index,cluster){
                    if(cluster.name == name) {
                        foundCluster = cluster.cluster;
                    }
                });
                return foundCluster;
            },

            playVideo: function(e) {
                e.preventDefault();
                var youtubeId = $(e.currentTarget).attr('data-youtubeId');
               	$(this.getSelectors()).find('.video-container iframe').attr('src','https://www.youtube.com/embed/'+youtubeId+'?rel=0&autoplay=1');
               	$('.map-video-wrapper').fadeIn();
            },
            
            stopVideo: function() {
                $('.map-video-wrapper').fadeOut();
               	$(this.getSelectors()).find('.video-container iframe').attr('src','');
            },

            // Judges Only
            navigateRecords: function(next) {
                var prevRecord = this.getRecord(this.selectedMarker.customData.id),
                    next = (typeof next == "undefined" ? true : next);
                var records = util.groupRecords(this.data,'type',prevRecord.type),
                    prevIndex = $.map(records, function(o,i) {
                        if(o.id == prevRecord.id) {
                            return i;
                        }
                    })[0];
                var newRecord = (next ? (records[prevIndex+1] || records[0]) : (records[prevIndex-1] || records[records.length-1]));
                var newMarker = this.getMarker(newRecord.id);
                if(newMarker) {
                    this.handleMarkerClick(newMarker,true);
                } else {
                    console.debug('Failed to retrieve marker to navigate to.',arguments)
                }
            },

            onMapClick: function(e) {
                if(this.getConfig().clickPopover) {
                    this.closeInfoWindows();
                }
            },

            // Finalists Only
            addFinalistButtonHandlers: function() {
                $('.finalists-button-container .btn.finalists').click(function (){
                    $('body').animate({
                        scrollTop: $('.semi-finalists').offset().top - 100
                    },1000);
                });
                $('.finalists-button-container .btn.top200').click(function (){
                    $('body').animate({
                        scrollTop: $('.top200-wrapper').offset().top - 100
                    },1500);
                });
                // judges
                $('.scroll-to-map').click(function (){
                    $('body').animate({
                        scrollTop: $('.finalistsMap').offset().top
                    },500);
                });
            }

        }

    });
});
