define('modules/news/listing/js/base.js',["app/config","jquery","app/module","app/util","templates"], function(config,$,module,util,templates) {
    return new module({
        name: 'news',
        selectors: false,
        remoteData: [config.urls.news],

        routes: [{
            route: 'news/(.*)',
            fn: function(hash) {
                this.showNews();
                this.openNewsPost(hash.split('/')[1]);
            }
        },{
            route: 'news',
            fn: 'showNews'
        }],

        initialize: function (data) {

            this.data = data;
            if(this.queuePage) {
                this.showNews();
                this.queuePage = false;
            }
            if(this.queueOpenPost) {
                this.openNewsPost(this.queueOpenPost);
                this.queueOpenPost = false;
            }
        },

        methods: {
            showNews: function() {
                if(!this.data) {
                    this.queuePage = true;
                    return;
                }
                var pageEl = $(templates.newspage(this.data));
                $('#centerMenuContent').addClass('sidebarPage');
                $('body').trigger('updatedashboard',[true,pageEl.clone(true,true).removeClass('hidden')]);

                $('.newsContainer').on('click', 'article,.readMoreBtn', function(e){
                    if(!$(e.target).is('a[href]')) {
                        e.preventDefault();
                        var $target = $(e.target);
                        var $article = $target.is('article') ?
                            $target :
                            $target.parents('article');

                        // the readMoreButton is visible based on css breakpoint
                        var $readMoreBtn = $article.find('.readMoreBtn');
                        var isMobile = !$readMoreBtn.is(':visible');

                        var shouldToggle = false;
                        if(isMobile){
                            // the user clicked the article, the button is not visible(mobile), togle the news
                            shouldToggle = true;
                        }
                        else if($target.hasClass('readMoreBtn')){
                            // the user clicked the button, which is visible(desktop), toggle the news
                            shouldToggle = true;
                        }
                        else{
                            // the user clicked the article, the button is visible(desktop), do nothing
                            shouldToggle = false;
                        }

                        if(shouldToggle){
                            $article.find('.postContent').slideToggle(300,$.noop).toggleClass('open');
                            // TODO: check what ".postDescription" is and if it is used... in the current template there is no such css class
                            $readMoreBtn.siblings('.postDescription').toggle();
                            $readMoreBtn.text($readMoreBtn.hasClass('btnClose') ? 'Read' : 'Close');
                            $readMoreBtn.toggleClass('btnClose');
                            return false;
                        }
                    }
                });
                $('body').trigger('pagenavupdate');
            },
            openNewsPost: function(postId) {
                if(!this.data) {
                    this.queueOpenPost = postId;
                    return;
                }
                var $article = $('.newsContainer article[data-news="'+postId+'"]');
                if ($article.length > 0) {
                    var $readMoreBtn = $article.find('.readMoreBtn');
                    if($readMoreBtn.is(':visible')){
                        $readMoreBtn.trigger('click');
                    }
                    else{
                        $article.trigger('click');
                    }

                    $article.scrollParent().scrollTop($article.position().top);
                }
            }
        }

    });
});
