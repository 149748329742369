define('modules/timeline/advanced/js/base.js',["app/module","app/config","jquery","app/util","templates","bootstrap/tooltip"], function(module,config,$,util,templates) {
    return new module({
        name: 'timeline',
        selectors: '.timelineSection',
        remoteData: [(config.urls.timeline)],

        routes: [{
            route: '(.*|home|timeline)',
            fn: 'refreshTimeline',
            extra: true,
            delay: 2000
        },{
            route: '(.*|home|timeline)',
            fn: function() {
                $('.timelinePoint.first').tooltip('show');
            },
            extra: true,
            delay: 5000
        }],

        config: {
            staggerPoints: 3,
            shortMonths: true,
            timelineWidth: '300%'
        },

        initialize: function(data) {
        	

            // Create timestamps for each record
            $(data).each(function(index,record){
                record.timestamp = new Date(record.date).getTime();
                data[index] = record;
            });
            
            // Sort by timestamps
            data = data.sort(util.dynamicSort('timestamp','id'));

            // Bounding
            var startMonth = new Date(new Date(data[0].timestamp).getFullYear(), new Date(data[0].timestamp).getMonth(), 1, 0, 0, 0),
                endMonth = new Date(new Date(data[data.length - 1].timestamp).getFullYear(), new Date(data[data.length - 1].timestamp).getMonth() + 1, 0, 0, 0, 0),
                startExtra = data[0].timestamp - startMonth.getTime(),
                endExtra = endMonth.getTime() - data[data.length - 1].timestamp;


            //var msCount = (data[data.length-1].timestamp-data[0].timestamp),
            //Let the range be the full period
            var msCount = (endMonth.getTime() - startMonth.getTime()),
                msIncrement = 100/msCount;
                
            // Render the template
            $.each(data,function(i,v){
                if(i == 0) {
                    data[i].startPeriod = true;
                } else if(data[i-1].period != data[i].period) {
                    data[i-1].endPeriod = true;
                    data[i].startPeriod = true;
                }
                if(i == data.length-1) {
                    data[i].endPeriod = true;
                }
            });
            $(this.getSelectors()).html($(templates.timeline({
                timelinePoints: data,
                msIncrement: msIncrement,
                start: data[0].timestamp
            })));

            this.setTimelinePointPositions();

            // Give the timeline gutters
            if($(window).width() > 1024) {
            	$('.timeline').css({
                	//width: this.getConfig().timelineWidth || '100%', //width: (100 - ((startExtra + endExtra) * msIncrement)) + '%',
                	'margin-left': (startExtra*msIncrement)+'%'
            	});
            }

            // Init tooltips
            $('.timelinePoint').tooltip();

            // Add the indicator for today's date and show the tooltip
            var nowPosition = (new Date().getTime()-data[0].timestamp)*msIncrement;
            $('<div class="timelineNow"><i class="material-icons">place</i></div>').attr('data-title','Today').css('left',nowPosition+'%').appendTo($('.timeline'));
            $('.timelineNow').tooltip({
                template: '<div class="tooltip tooltipNow"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            });

            // Tooltip listeners
            $('.timelinePoint, .timelineNow').on('show.bs.tooltip',function(){
                if(!$(this).hasClass('active')) {
                    $('.timelinePoint.active').tooltip('hide');
                }
            }).on('hide.bs.tooltip',function(){
                if($(this).hasClass('active')) {
                    return false; // Do not allow closing on currently viewed point
                } else {
                    if(!$('.timelinePoint.active').next().hasClass('in')) {
                        $('.timelinePoint.active').tooltip('show'); // Ensure the active point is reshown
                    }
                }
            });

            // Timeline point handlers
            $('.timelinePoint').on('click',$.proxy(function(e){
                e.preventDefault();
                this.handleTimelinePointClick(e);
            },this));

            // Timeline nav handlers
            $('.timelineNav').on('click',$.proxy(function(e){
                e.preventDefault();
                this.handleTimelineNav(e);
            },this));

            // Update the init Period information
            $('.timeline-period h3').html( data[0].period );
            $('.timeline-period h6').html( data[0].periodlength );
            $('.timeline .connectLine[data-period="'+data[0].period+'"]').addClass('highlight');

            //===== Init Calendar =====//

            var firstMonth  = new Date(data[0].timestamp),
                lastMonth       = new Date(data[data.length - 1].timestamp),
            // Pointer to the DOM
                calendar        = $('.timeline-calendar ul'),
            // Array of month name strings
                monthNames      = ['January','February','March','April','May','June','July','August','September','October','November','December'],
            // Counts the number of months between our two dates
                monthCount      = 0;

            // Fetch the first date in the timestamp
            var curMonth = firstMonth;

            // Adjust our last month to offset for final month
            //lastMonth.setMonth(lastMonth.getMonth() + 1);

            // For each month...
            while ( curMonth < endMonth ) {

                // Fetch the month's name
                var curMonthName = monthNames[curMonth.getMonth()];

                // Get any timeline points and periods that are inside this month
                var periods = $.map(data,function(v){
                    var month = new Date(v.timestamp).getMonth();
                    if(month == curMonth.getMonth()) {
                        return v.period;
                    } else {
                        return null;
                    }
                });

                // Append the month to our calendar
                calendar.append('<li data-period="'+periods.join(',')+'">' + (this.getConfig().shortMonths ? curMonthName.substr(0,3) : curMonthName) + '</li>');

                // Increase the current month by 1
                curMonth.setMonth( curMonth.getMonth() + 1 );
                // Increase our month counter
                monthCount++;
            }

            // Finally, adjust the width of our month names evenly
            var borderWidth = $('.timeline-calendar li').css('border-right-width').replace("px", "");

            $('.timeline-calendar li').width( (100 / monthCount) - (borderWidth) + '%' );

            // timeout fixes the bug of the first tool tip
            // showing up in the wrong place
            if (($('.timeline').css('margin-left') <= '1%' ) && ($('.timelineContainer .timeline-bottom').css('width') == '100%' )) {
            	$('.timelinePoint.first').css('left','1%');
            }

            this.setTimelinePeriodTitles();

            this.timelineDrop();

        	$(window).smartresize($.proxy(this.refreshTimeline,this));


        },

        methods: {
            handleTimelinePointClick: function (e) {
                var pointEl = $(e.currentTarget);
                $('.timeline .timelinePoint').removeClass('active');
                pointEl.addClass('active');
                $('.timeline .timelinePoint').tooltip('hide');
                $('.timeline-bottom .tooltip').hide();
                $('.timeline').css('position', 'relative');
                $('.timeline').animate({
                    left: (-parseFloat(pointEl.css('left')) * .9)
                });
                $(this.getSelectors()).find('.timelineBackground').css({
                    'background-position': (parseFloat(pointEl.css('left')) * .4)+'px 0px' // TODO - Refine the movement amount
                });
                $('.timelineNavCount').text($('.timelinePoint').index(pointEl)+1);
                setTimeout(function(){
                    pointEl.attr('data-trigger','manual').tooltip('show');
                },410);
            },

            handleTimelineNav: function(e) {
                var direction = $(e.currentTarget).hasClass('right'),
                    currentIndex = $('.timelinePoint').index($('.timeline .timelinePoint.active'));
                if((currentIndex+(direction ? 1 : -1)) >= $('.timelinePoint').length) {
                    $('.timelinePoint').first().trigger('click');
                } else {
                    $('.timelinePoint').eq(currentIndex+(direction ? 1 : -1)).trigger('click');
                }
            },

            setTimelinePointPositions: function() {
                $('.timeline .timelinePoint').each($.proxy(function(i,v){
                    var offset = ((i+1) % this.getConfig().staggerPoints);
                    if(offset > 0) {
                        var overlap = util.checkElementOverlap($('.timeline .timelinePoint').eq(i+1),$(v));
                        if(overlap) {
                            $('.timeline .timelinePoint').eq(i+1).css('margin-left', '6px');
                        }
                        $(v).addClass('offsetTop'+offset);
                    }
                },this));
            },

            setTimelinePeriodTitles: function() {
                $('.timelinePeriod').each(function(i,v){
                    var width = 0;
                    $(v).find('.connectLine').each(function(ii,vv){
                        width += $(vv).width();
                        if(ii == 0) {
                            $(v).find('.periodTitle').css('left',parseInt($(vv).css('left'))+20);
                        }
                    });
                    $(v).find('.periodTitle').width(width);
                });

            },

            refreshTooltipPositions: function() {
                $('.timeline-bottom .tooltip').hide();
                $('.timelinePoint').tooltip('enable');
                $('.timeline-bottom .tooltip').tooltip('hide');
            },

            refreshTimeline: function() {
                // TODO - Refine the timeout handling in this section, this is due to the animation running on the timeline resize
                this.setTimelinePointPositions();
                this.setTimelinePeriodTitles();
                setTimeout($.proxy(function(){
                    this.setTimelinePointPositions();
                    this.setTimelinePeriodTitles();
                    setTimeout($.proxy(this.refreshTooltipPositions,this),500);
                    this.timelineDrop();
                },this),350);
            },

            timelineDrop: function() {
                var dropShadowWidth = 0;
                $('.periodTitle').each(function(){
                    var periodTitleWidth = $(this).width();
                    dropShadowWidth = dropShadowWidth + periodTitleWidth;
                });

                if($(window).width() > 768)  {
                    $('.timelineDropShadow').css('width',dropShadowWidth + 25);
                }
                else {
                    $('.timelineDropShadow').css('width',dropShadowWidth + 15 );
                }
            }
        }
    })
});
